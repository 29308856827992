import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { styled, Typography } from '@mui/material';
import { GetPlansYear } from '@coverright/utils';
import {
  CoverageEntryOutput,
  DrugCalculationStage,
} from '@coverright/data-access/types/medicare';
import { useIsV3Theme } from '@coverright/features';

interface DCBCoverageProps {
  plan: IDrugCostBreakdownPlan;
}

export default function Coverage({ plan }: DCBCoverageProps) {
  const hitIC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.InitialCoverage
  );
  const hitCG = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.CoverageGap
  );
  const hitCC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.Catastrophic
  );

  return (
    <>
      <InitialCoverageSection plan={plan} entry={hitIC} />
      <CoverageGapSection plan={plan} entry={hitCG} />
      <CatastrophicSection plan={plan} entry={hitCC} />
    </>
  );
}

function InitialCoverageSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
  className?: string;
}) {
  return (
    <Section
      title={'Initial Coverage'}
      label={
        <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>Initial Coverage Phase</span> this
              year under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Initial Coverage Phase</span> this
              year under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
        </>
      }
      content={
        <>
          {props.plan?.drugDetails?.mrxAltDedAmount
            ? `This plan has a drug deductible of ${props.plan?.drugDetails?.mrxAltDedAmount}. That means you'll pay the retail drug price for your covered drugs up until that point, before your insurance starts paying.`
            : `This plan doesn't have a drug deductible. That means you'll pay a copay or co-insurance for all of your covered prescription drugs until you reach the Coverage Gap.`}
        </>
      }
    />
  );
}

function CoverageGapSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
}) {
  const is2025 = props.plan.planYear?.toString() === 'Year2025'
  return (
    <Section
      title={'Coverage Gap'}
      label={
        !is2025 ? <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>
                Coverage Gap Phase in {intToMonth(props.entry.month)}
              </span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Coverage Gap Phase</span> this year
              under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
          </> : <></>
      }
      content={
        <>
          After you pay your deductible (if applicable), you’ll pay a copay or
          co-insurance on your drugs up until the sum of your portion of costs
          and what the plan has paid is{' '}
          {props.plan?.drugDetails?.initialCoverageLimit} (in{' '}
          {(props.plan.planYear || GetPlansYear())
            .toString()
            .replace('Year', '')}
          ) per year{is2025 ? '' : ', and then you’ll enter the Coverage Gap (or “donut hole”) phase, where you will typically pay 25% of retail cost for your drugs'}.
        </>
      }
    />
  );
}

function CatastrophicSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
}) {
  return (
    <Section
      title={'Catastrophic Coverage'}
      label={
        <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>
                Catastrophic Coverage Phase in {intToMonth(props.entry.month)}
              </span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Catastrophic Coverage Phase</span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
        </>
      }
      content={
        <>
          You will enter the Catastrophic Coverage phase once your total
          out-of-pocket costs reaches{' '}
          {props.plan?.drugDetails?.catastrophicThreshold} (in{' '}
          {(props.plan.planYear || GetPlansYear())
            .toString()
            .replace('Year', '')}
          ). Under this phase, you’ll pay the{' '}
          {props.plan?.drugDetails?.catastrophicCopayGeneric} of retail price
          for generic drugs, and{' '}
          {props.plan?.drugDetails?.catastrophicCopayBranded} of retail price
          for branded drugs.
        </>
      }
    />
  );
}

interface SectionProps {
  title: string;
  label: React.JSX.Element;
  content: React.JSX.Element;
}

const Section = ({ title, label, content }: SectionProps) => {
  const isV3 = useIsV3Theme();

  return (
    <div>
      {isV3 && (
        <Typography className={'fs-24 lh-32 epilogue semi-bold'}>
          {title}
        </Typography>
      )}
      <Label> {label} </Label>
      <Content> {content} </Content>
    </div>
  );
};

const Label = styled(Typography)(() => {
  const isV3 = useIsV3Theme();
  if (isV3) {
    return {
      color: '#666666',
    };
  }
  return {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  };
});

const Content = styled(Typography)(() => {
  const isV3 = useIsV3Theme();
  if (isV3) {
    return {
      color: '#666666',
    };
  }
  return {
    fontSize: 13,
    lineHeight: '20px',
  };
});

function intToMonth(month: number): string {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
}
