'use client';
import * as React from 'react';
import { CRModal } from '@coverright/ui/shared/crmodal';
import { Box, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react/dist/ssr/X';
import { CalendlyCallType, CalendlyProps } from '../lib/types';
import { Calendly } from './calendly';

export interface ModalProps extends Partial<CalendlyProps> {
  onClose: (value: boolean) => void;
  width?: number | string;
  open?: boolean;
  logEvent?: (message: string, data?: any) => void;
}

export const CalendlyModal = (props: ModalProps) => {
  const { width, logEvent, ...rest } = props;

  React.useEffect(() => {
    if (logEvent && props.open !== false) {
      logEvent('Calendly modal opened');
    }
  }, [props.open, logEvent]);

  const onClose = (val: boolean) => {
    logEvent && logEvent('Calendly modal closed', { scheduled: val });
    props.onClose(val);
  };

  return (
    <CRModal
      slotProps={{
        backdrop: {
          sx: {
            background: 'rgba(0, 0, 0, 0.25)'
          }
        }
      }}
      width={width || 725}
      id={'calendly-modal'}
      paperPadding={0}
      open={props.open === undefined ? true : props.open}
      onClose={() => onClose(false)}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1 }}
          data-test="modal-close-button"
          onClick={() => onClose(false)}
        >
          <X size={32} color="#000" />
        </IconButton>
        <Box sx={{ mx: 4, mt: 4, mb: 1 }}>
          <Typography sx={{ lineHeight: '21px', fontWeight: 600, mb: 1 }}>
            Schedule a call
          </Typography>
          <Typography sx={{ fontSize: 16, lineHeight: '24px' }}>
            We have licensed insurance agents ready to help answer your
            questions. Select date and time that works best for you.
          </Typography>
        </Box>
        <Calendly
          {...rest}
          type={rest.type || CalendlyCallType.consultation}
          maxWidth={1000}
          onEventScheduled={(data) => {
            rest.onEventScheduled && rest.onEventScheduled(data);
            onClose(true);
          }}
        />
        <Box sx={{ m: 4 }}>
          <Typography sx={{ fontSize: 14, lineHeight: '24px' }}>
            By submitting my phone number and personal information above and clicking ’Continue’ above, I confirm
            that the phone number provided is my own, and I provide my express written consent to be contacted by
            CoverRight Insurance Services Inc. or one of its licensed insurance agents at the phone
            number I have provided above regarding various Medicare products including Medicare Advantage Plans,
            Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and informational content,
            through the use of text message, email and/or live, automated and pre-recorded telephone calls, AI
            generative voice calls, prerecorded messages played when a connection is made, or prerecorded voicemail
            messages even if my number is on a Do Not Call list. I further understand and agree that my communications
            with CoverRight Inc. are subject to CoverRight Inc.'s  <a href={'https://coverright.com/privacy/'} target={'_blank'}>
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href={'https://coverright.com/coverright-terms-of-use/'}
              target={'_blank'}
            >
              Terms of Use
            </a>
            .
            <br />
            <br />
            This is a solicitation for insurance. Your information will be
            provided to a licensed agent for future contact.
            <br />
            <br />
            I understand my consents to receive text messages and telephone
            calls are not required to receive services from CoverRight Inc.; I
            may instead reach CoverRight Inc. directly at +1 (888) 969-7667
            (TTY: 711) and I can revoke my consents at any time by emailing
            hello@coverright.com.
            <br />
            <br />
            CoverRight Insurance Services Inc. represents Medicare Advantage
            HMO, PPO and PFFS organizations that have a Medicare contract.
            Enrollment depends on the plan’s contract renewal with Medicare.
            <br />
            <br />
            We do not offer every plan available in your area. Currently we
            represent 36 organizations across the nation and 3108 plans across
            the nation. Please contact Medicare.gov, 1-800-MEDICARE, or your
            local State Health Insurance Program (SHIP) to get information on
            all of your options.
            <br />
            <br />
            For a complete list of available plans please contact 1-800-MEDICARE (1-800-633-4227). TTY/RTT users should call 1-877-486-2048, 24 hours a day/7 days a week or consult Medicare.gov.
            <br />
            <br />
            Medicare beneficiaries can file a complaint with the Centers for Medicare & Medicaid Services by calling 1-800-MEDICARE 24 hours a day/7 days a week or using the medicare.gov site.
            <br />
            <br />
            Not all plans offer all of these benefits. Benefits may vary by
            carrier and location. Limitations and exclusions may apply.
            <br />
            <br />
            Enrollment in a plan may be limited to certain times of the year
            unless you qualify for a Special Enrollment Period or you are in
            your Medicare Initial Enrollment Period.
          </Typography>
        </Box>
      </Box>
    </CRModal>
  );
};
