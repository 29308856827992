import { SxProps } from '@mui/material';

export interface Client {
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
}

export interface CalendlyProps {
  onEventScheduled?: (data: any) => void;
  dataUrl?: string;
  source?: string;
  height?: string | number;
  maxWidth?: string | number;
  sx?: SxProps;
  type?: CalendlyCallType;
  agent?: {enrollmentMeetingUrl?: string | null, planReviewUrl?: string | null, b2bMeetingUrl?: string | null, consultationUrl?: string | null};
  client?: Client;
}

export enum CalendlyCallType {
  enrollment,
  planReview,
  consultation,
  b2b,
}
